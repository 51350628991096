<template>
  <section class="createAccount-area inner-page pb-5">
    <!-- <ConfirmDialog appendTo="#app" :draggable="false" /> -->
    <div class="container">
      <div class="createAccountform dir-rtl">
        <div class="form-cont">
          <div v-if="active_step <= 5">
            <Steps :model="items" />
          </div>
          <div
            class="form-container"
            :style="active_step <= 5 ? 'border-top: 1px solid #dadada' : ''"
          >
            <Step1
              :payload="payload"
              :verified_info="verified_info"
              @close="close"
              @next="next"
              v-if="active_step == 1"
            />
            <Step2
              :payload="payload"
              :verified_info="verified_info"
              :apierrors="apierrors"
              @close="close"
              @next="next"
              @fileChange="fileChange"
              @removeFile="removeFile"
              v-else-if="active_step == 2"
            />
            <Step3
              :payload="payload"
              :apierrors="apierrors"
              @close="close"
              @next="next"
              @fileChange="fileChange"
              @removeFile="removeFile"
              @verifyIBAN="() => (this.payload.is_iban_verified = true)"
              v-else-if="active_step == 3"
            />
            <Step4
              :payload="payload"
              :apierrors="apierrors"
              @close="close"
              @next="next"
              @addServiceProvider="addServiceProvider"
              @removeServiceProvider="removeServiceProvider"
              @setPayload="setPayload"
              v-else-if="active_step == 4 "
            />
            <Step5
              :payload="payload"
              :verified_info="verified_info"
              :apierrors="apierrors"
              @openVerificationStep="openVerificationStep"
              @close="close"
              @next="next"
              v-else-if="active_step === 5 && !open_verification_step"
            />
            <Step5Verification
              :payload="payload"
              @closeVerificationStep="closeVerificationStep"
              @createCharity="createCharity"
              @setPayload="setPayload"
              :verified_info="verified_info"
              v-else-if="active_step == 5 && open_verification_step"
            />
            <AfterSubmitModal
              :display="open_modal"
              :is_success="is_success"
              @close="closeModal"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Steps from "primevue/steps";
import Step1 from "./step1.vue";
import Step5Verification from "./step5_verification.vue";
import Step2 from "./step2.vue";
import Step3 from "./step3.vue";
import Step4 from "./step4.vue";
import Step5 from "./step5.vue";
import AfterSubmitModal from "./after-submit-modal";
import { getLocalValue } from "@/hooks/useLocalStorage";
import CharityRegisterService from "@/services/charity-register.service";

import { toHijri } from "hijri-converter";

export default {
  name: "SignIn",
  components: {
    Steps,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step5Verification,
    AfterSubmitModal,
  },
  data() {
    return {
      open_modal: false,
      is_success: null,
      active_step: 1,
      items: [
        {
          label: "1",
          to: `/create-account/1`,
        },
        {
          label: "2",
          to: "/create-account/2",
        },
        {
          label: "3",
          to: "/create-account/3",
        },
        {
          label: "4",
          to: "/create-account/4",
        },
        {
          label: "5",
          to: "/create-account/5",
        },
      ],
      loading: false,
      error: null,
      open_verification_step: false,
      payload: {
        id: 0,
        national_id: null,
        hijri_birthdate: null,
        gregorian_birthdate: null,
        mobile_number: null,
        email: null,
        the_charity_manager_is_the_chairman: false,

        is_mobile_verified: false,
        is_email_verified: false,

        is_chairman_mobile_verified: false,
        is_charity_email_verified: false,

        iban: null,
        is_iban_verified: false,
        number_of_retry: 0,

        charity_license_number: null,
        license_expiry_hijri_date: null,
        license_expiry_gregorian_date: null,
        license_attachment: null,

        bank_name: null,
        bank_account_attachment: null,

        region: null,
        city: null,
        charity_type: null,
        charity_logo: null,
        charity_name: null,
        charity_specialization: null,
        charity_phone: null,
        charity_email: null,
        charity_website: null,
        longitude: 46.664734,
        latitude: 24.710988,
        chairman_name: null,
        chairman_mobile: null,

        number_of_service_provider_agreements: null,
        service_provider: null,

        association_director_name: null,
        is_accepted_terms: false,

        flag: null,
      },
      apierrors: {
        national_id: null,
        hijri_birthdate: null,
        gregorian_birthdate: null,
        mobile_number: null,
        email: null,
        the_charity_manager_is_the_chairman: null,

        is_mobile_verified: null,
        is_email_verified: null,

        is_chairman_mobile_verified: null,
        is_charity_email_verified: null,

        iban: null,
        is_iban_verified: null,
        number_of_retry: null,

        charity_license_number: null,
        license_expiry_hijri_date: null,
        license_expiry_gregorian_date: null,
        license_attachment: null,

        bank_name: null,
        bank_account_attachment: null,

        region: null,
        city: null,
        charity_type: null,
        charity_logo: null,
        charity_name: null,
        charity_specialization: null,
        charity_phone: null,
        charity_email: null,
        charity_website: null,
        chairman_name: null,
        chairman_mobile: null,

        number_of_service_provider_agreements: null,
        service_provider: null,

        association_director_name: null,
        is_accepted_terms: null,

        flag: null,
      },
      verified_info: {
        first_name: null,
        last_name: null,
        father_name: null,
        gender: null,
        identity_card_expiry: null,
      },
      gregorian_max_date: null,
      hijri_max_date: null,
      userFound: false,
    };
  },
  methods: {
    createAccount() {},
    async setInitialPayload() {
      this.verified_info = getLocalValue("verified_info") || {};
      const charityRequest = getLocalValue("charity_request");
      if (charityRequest) {
        for (let i = 1; i <= 5; i++) {
          const step = charityRequest[`step${i}`];
          if (step) {
            for (let key in step) {
              this.payload[key] = step[key];
            }
            this.active_step = i + 1;
          } else {
            this.active_step = i;
            break;
          }
        }
      }
      this.$router.push(`/create-account/${this.active_step}`);

      const uuidPattern =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      if (this.payload.iban && uuidPattern.test(this.payload.iban)) {
        this.payload.iban = await this.getIBANfromUUID(this.payload.iban);
      }
    },
    setStep() {
      this.active_step = parseInt(this.$route.params.step, 10);
    },
    closeModal() {
      this.open_modal = false;
      if (this.is_success) {
        this.$router.push("/");
        localStorage.removeItem("charity_request");
        localStorage.removeItem("verified_info");
      }
    },
    async getIBANfromUUID(ibanUUID) {
      const response = await CharityRegisterService.getIBANfromUUID(ibanUUID);
      const iban = response.data.result.iban;
      return iban;
    },
    close() {
      if (this.active_step > 1) {
        this.$router.push(`/create-account/${this.active_step - 1}`);
      }
      else this.$router.push("/sign-in?tab=1");
    },
    next() {
      if (this.active_step < 5)
        this.$router.push(`/create-account/${this.active_step + 1}`);
      else this.$router.push("/sign-in?tab=1");
    },
    openVerificationStep() {
      this.open_verification_step = true;
      this.$router.push(`/create-account/5?open_verification=${true}`);
    },
    closeVerificationStep() {
      this.open_verification_step = false;
      this.$router.push(`/create-account/5`);
    },
    addServiceProvider() {
      this.payload.service_provider.push({ hospital_id: null, discount: null });
    },
    removeServiceProvider(index) {
      this.payload.service_provider.splice(index, 1);
    },
    fileChange(event, key) {
      if (
        event &&
        event.target &&
        event.target.files &&
        event.target.files.length
      ) {
        this.payload[key] = event.target.files[0];
      }
    },
    removeFile(key) {
      this.payload[key] = null;
    },
    setPayload(key, val) {
      this.payload[key] = val;
    },
    // Commenting this code If In future we want user to accept/decline the confirmation
    // confirm(values, id) {
    //   this.$confirm.require({
    //     header: 'طلب الأعمال الخيرية قيد التسجيل بالفعل. هل ترغب في المتابعة؟',
    //     acceptLabel: 'تأكيد',
    //     rejectLabel: 'لا',
    //     acceptClass: 'p-button-rounded p-button-primary px-5 mx-2',
    //     rejectClass: 'p-button-rounded p-button-secondary px-5 mx-2',
    //     accept: () => {
    //       for(let key in values) {
    //         this.payload[key] = values[key];
    //       }
    //       this.userFound = true;
    //       this.openVerificationStep1(id)
    //     },
    //     reject: () => {
    //       this.userFound = true;
    //       this.openVerificationStep1(id)
    //     }
    //   });
    // },
    createCharity() {
      //send only file atttachments' base64 data
      const data = this.payload;
      data.charity_logo = data.charity_logo.data;
      data.license_attachment = data.license_attachment.data;
      data.bank_account_attachment = data.bank_account_attachment.data;
      CharityRegisterService.createCharity(data)
        .then(() => {
          this.is_success = true;
          this.open_modal = true;
        })
        .catch((error) => {
          for (let key in error.response.data.errors) {
            this.apierrors[key] = error.response.data.errors[key];
          }
          //redirect to step 2 so user goes through all errors  on all steps
          this.$router.push(`/create-account/2`);
        });
    },
  },
  watch: {
    $route() {
      this.setStep();
    },
    active_step(val) {
      if (
        val == 2 &&
        this.payload.the_charity_manager_is_the_chairman &&
        this.payload.chairman_mobile != this.payload.mobile_number
      ) {
        this.payload.chairman_mobile = this.payload.mobile_number;
      }
    },
  },
  mounted() {
    this.setInitialPayload();
  },
  created() {
    this.gregorian_max_date = new Date();
    const dateHijriObj = toHijri(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      new Date().getDate()
    );
    this.hijri_max_date = new Date(
      dateHijriObj.hy,
      dateHijriObj.hm - 1,
      dateHijriObj.hd
    );
    this.payload.gregorian_birthdate = this.gregorian_max_date;
    this.payload.hijri_birthdate = this.hijri_max_date;
    this.payload.license_expiry_gregorian_date = this.gregorian_max_date;
    this.payload.license_expiry_hijri_date = this.hijri_max_date;
  },
};
</script>

<style lang="scss" scoped>
.createAccount-area {
  .container {
    padding: 0 0 45px 0;
  }

  .form-container {
    padding-top: 1rem;
  }

  .form-cont {
    padding: 2rem;
  }

  .logo {
    width: 30px;
    margin-left: 10px;
  }

  .createAccountform {
    background-color: #ffffff;
    border-radius: 10px;
    width: 40%;
    display: block;
    margin: 5px auto;
    direction: rtl;
    text-align: right;
    font-weight: lighter;
  }

  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .dir-rtl {
    direction: rtl;
  }

  :deep(.p-tabview .p-tabview-nav li) {
    width: 50% !important;
  }

  :deep(.p-tabview .p-tabview-nav li .p-tabview-nav-link) {
    display: block;
    text-align: center;
  }

  :deep(
      .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus
    ) {
    box-shadow: none;
  }

  :deep(.p-float-label > label) {
    left: 0 !important;
    right: 0.75rem;
  }
}
</style>

<style lang="scss">
.p-steps-title {
  color: transparent !important;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #12b2aa !important;
  color: #ffffff !important;
}

.p-steps .p-steps-item:before,
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  border-color: #b2b2b2 !important;
}

.p-steps .p-steps-item:first-child:before {
  left: 0;
  width: 50%;
}

.p-steps .p-steps-item:last-child:before {
  left: 50%;
  width: 50%;
}
</style>
