<template>
    <div :class="[noSpacing ? '' : 'mb-2 mt-4 ', 'w-100 custom-input-number']">
        <span class="p-float-label">
            <span :class="{ 'p-inputwrapper-filled' : modelValue !== null && modelValue !== undefined && modelValue !== '' }">
                <input 
                    :value="modelValue"
                    class="w-100 p-inputtext p-component p-inputnumber-input"
                    @input="handleInput"
                    @keydown="checkInput"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    :maxlength="maxLength"
                    :required="required"
                    :mode="mode"
                    :style="{ height: inputFieldHeight ? inputFieldHeight : '', width: inputFieldWidth ? inputFieldWidth + '!important' : '' }"
                />
                <label class="w-100" v-if="label">{{ label }}</label>
            </span>
        </span>
    </div>
</template>

<script setup>
import { debounce } from "@/helpers/helpers.ts";

const props = defineProps({
    label: {
        type: String,
    },
    modelValue: {
        type: [String, Number, BigInt],
        required: true
    },
    disabled:{
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: ''
    },
    maxLength: {
        type: Number,
    },
    min: {
        type: Number
    },
    max: {
        type: Number,
    },
    noSpacing:{
        type: Boolean,
    },
    required: {
        type: Boolean,
        default: false
    },
    mode: {
        type: String,
    },
    phoneNumber: {
        type: Boolean,
        default: false
    },
    inputFieldHeight: {
        type: Number,
        required: false,
    },
    inputFieldWidth: {
        type: Number,
        required: false,
    },
})

const emit = defineEmits(['update:modelValue'])

// Here we are debouncing the input to check if the input is between the min and max values
let debouncedCheckMaxMinInput = debounce((value, target) => {
  let numValue = parseInt(value);
  if (numValue < props.min) {
    target.value = props.min;
  } else if (numValue > props.max) {
    target.value = props.max;
  } else {
    target.value = numValue;
  }
 props.mode === 'decimal' ? emit('update:modelValue', parseFloat(target.value)) : emit('update:modelValue', Number(Math.round(target.value)));
}, 1000);

// Here we are handling the input and checking if the input is between the min and max values
const handleInput = (e) => {
    if(e.target.value === '') {
        emit('update:modelValue', '');
        return;
    }
    if ((props.min !== undefined || props.max !== undefined)) {
        debouncedCheckMaxMinInput(e.target.value, e.target);
    } else {
        // if we need to accept zero e.g(01|02|03|04|05|06|07|08|09)
        if(props.phoneNumber) {
            emit('update:modelValue', e.target.value);
            return;
        }
        props.mode === 'decimal' ? emit('update:modelValue', parseFloat(e.target.value)) : emit('update:modelValue', Number(Math.round(e.target.value)));
    }
}

// Here we are checking if the input is a number or not
const checkInput = (event) => {
    // Allow: backspace, delete, tab, escape, enter, and arrow keys
    // On special keys we allow decimal point if the mode is decimal
    const specialKeys = props.mode === 'decimal' ? [8, 9, 27, 13, 110, 190] : [8, 9, 27, 13];
    if (specialKeys.indexOf(event.keyCode) !== -1 ||
        // Allow: Ctrl+A, Command+A
        (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)) || 
        // Allow: home, end, left, right, down, up
        (event.keyCode >= 35 && event.keyCode <= 40)) {
            return;  // let it happen, don't do anything
    }
    // Handle copy/cut/pasting
    if (props.phoneNumber && (event.keyCode === 67 || event.keyCode === 86 || event.keyCode === 88) && event.ctrlKey === true) {
        return;
    }
    // Ensure that it is a number and stop the keypress if not
    preventNonNumericInput(event);
}

const preventNonNumericInput = (event) => {
    const regex = /^[0-9]*$/;
    if (!regex.test(event.key)) {
        event.preventDefault();
    }
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/input-number";
</style>